<script lang="ts" setup>
const { open } = useNlcModals();
</script>
<template>
	<div class="daily-wheel-widget" data-tid="daily-wheel-widget" @click="open('LazyOModalDailyWheel')">
		<MDailyWheel />
	</div>
</template>

<style scoped lang="scss">
.daily-wheel-widget {
	position: relative;
	right: -50%;
	width: 70px;
	animation: animation 16.5s infinite linear;
	@include media-breakpoint-up(lg) {
		cursor: pointer;
		width: 120px;
	}
}
@keyframes animation {
	0%,
	42% {
		right: -50%;
	}
	2%,
	40% {
		right: -25%;
	}
}
</style>
